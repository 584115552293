
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body, div, h1, h2, h3, h4, p, li, a {
	font-family: 'Helvetica';
}
a {
	color: #1565C0;
	text-decoration: none;
}
body {
	background-image: url(/static/img/minerva.ff03700.jpg);
	background-repeat: no-repeat;
  background-size: cover;
	background-attachment: fixed;
	
	padding: 0px;
	margin: 0px;
	border: 0px;
}
img {
	padding: 0px;
	margin: 0px;
	border: 0px;
}
img.logo {
	margin-top:20px;
	margin-left:20px;
	max-width: calc(100% - 40px);
}
#tabs {
	padding:20px;
}
ul.tabs {
	list-style-type: none;
    margin: 0;
    padding: 0;
}
ul.tabs li {
	display:inline-block;
	margin: 0;
    padding: 0;
	background-color: #EEE;
	border-radius: 5px 5px 0px 0px;
	border-top: 1px solid gray;
	border-right: 1px solid gray;
	border-bottom: 1px solid gray;
	position: relative;
	z-index:10;
}
ul.tabs li.active-tab {
	border-bottom: 1px solid white;
	background-color: white;
}
ul.tabs li:first-child {
	border-left: 1px solid gray;
}
ul.tabs li a {
	display:inline-block;
	margin: 0;
    padding: 8px;
	font-weight: bold;
	color: black;
}
div.tab {
	display:none;
	background-color: white;
	padding: 8px;
	border: 1px solid gray;
	position: relative;
	top: -1px;
	z-index:5;
	padding-bottom: 60px;
}
div.active-tab {
	display:block;
}
.boatimages {
	text-align: center;
	padding: 20px;
}
.boatimages span {
	max-width: 500px;
	margin: 10px;
	min-width: 100px;
	display:inline-block;
}
.boatimages span img {
	width: 100%;
}
.langselect {
	position: absolute;
	top: 0px;
	right: 0px;
	padding: 4px;
	font-size: 12px;
}
